import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { light } from "./styles/Theme";
import {Formacao} from './components/formacao/index';



export function App() {
  return (
    <>
      <ThemeProvider theme={light}>
        <GlobalStyles />
        <Formacao />
      </ThemeProvider>
    </>
    
  )
}

export default App
