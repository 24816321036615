/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import  './styles.css';
export function Formacao() {

    return (
            <section>

                <div style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    width: "100vw"
                }} >
                    <iframe src="https://forms.office.com/r/NaiLYpQFAY" style={{
                        width: '100%',
                        height: '100vh',
                        border: 'none'
                    
                    }}></iframe>
                </div>
            </section>
    )
}